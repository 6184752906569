import React from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import { useConciergePageData } from "../hooks/useConciergePageData"
import ContactForm from "../components/molecules/contactForm"

const ConciergePage = ({ data: previewData }) => {
  const { uid, data } = useConciergePageData(previewData)

  const bannerData =
    data.body.length > 0 ? { ...data.body[0].primary } : undefined
  return (
    <Layout bookBannerData={bannerData}>
      <SEO title={uid} />
      <PageHeader {...data} />
      <Container>
        <ContactForm formType="concierge" hasFileUpload={false} />
      </Container>
    </Layout>
  )
}

export default withPreview(ConciergePage)

const Container = styled.div`
  max-width: 600px;
  padding: 0 40px;
  margin: 0 auto;
  display: grid;
  justify-items: center;
`
