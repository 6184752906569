import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useConciergePageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicConciergePage {
        _previewable
        uid
        data {
          title {
            html
          }
          tagline {
            raw
            text
          }
          text {
            html
            text
            raw
          }
          body {
            ... on PrismicConciergePageBodyBook {
              id
              primary {
                link {
                  url
                  link_type
                }
                button_text
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
  })
  return mergedData.prismicConciergePage
}
